<!--
 * @Description: 微断
 * @Author: v_binqin
 * @Date: 2022-11-27 01:58:11
 * @LastEditors: v_binqin
 * @LastEditTime: 2022-11-27 16:19:24
-->

<template>
  <div class="microbreak">
    <van-steps :active="active">
      <van-step>创建设备</van-step>
      <van-step>上传图片</van-step>
    </van-steps>
    <van-form v-if="active == 0" @submit="onSubmit">
      <van-field
        v-model="id"
        name="设备ID"
        label="设备ID"
        placeholder="设备ID"
        :rules="[{ required: true, message: '请填写设备ID' }]"
      >
        <template #button>
          <van-button size="small" icon="scan" />
        </template>
      </van-field>
      <van-field
        v-model="name"
        name="设备名称"
        label="设备名称"
        placeholder="设备名称"
        :rules="[{ required: true, message: '请填写设备名称' }]"
      />
      <van-button
        class="bottom-btn"
        round
        block
        type="info"
        native-type="submit"
      >
        下一步
      </van-button>
    </van-form>
    <div v-else class="upload">
      <van-uploader
        v-model="fileList"
        :after-read="afterRead"
        multiple
        :max-count="2"
      />
      <van-button class="bottom-btn" round block type="info" @click="sendData">
        提交
      </van-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: 0,
      id: '',
      name: '',
      fileList: [
        {
          url: 'https://img01.yzcdn.cn/vant/leaf.jpg',
          status: 'uploading',
          message: '上传中...',
        },
        {
          url: 'https://img01.yzcdn.cn/vant/tree.jpg',
          status: 'failed',
          message: '上传失败',
        },
      ],
    }
  },
  created() {},
  methods: {
    onSubmit(values) {
      this.active = 1
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = '上传中...'

      setTimeout(() => {
        file.status = 'failed'
        file.message = '上传失败'
      }, 1000)
    },
    sendData() {
      this.$router.push('/')
    },
  },
}
</script>
<style lang="less" scoped>
.microbreak {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.van-steps {
  padding: 10px 20% 0;
}
.van-button {
  border: none;
}
.upload {
  padding: 0 20px;
}
.upload,
.van-form {
  position: relative;
  flex: 1;
  .bottom-btn {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
</style>
